import "./App.scss";
import { useState, useEffect } from "react";
import { Route, Routes, useLocation, Navigate, useNavigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Components/Header/Header";
import AboutAnR from "./Pages/AboutAnR/AboutAnR";
import OurStation from "./Pages/OurStation/OurStation";
import InfoKebencanaan from "./Pages/Kebencanaan/InfoKebencanaan";
import Publication from "./Pages/Publication/Publication";
import Footer from "./Components/Footer/Footer";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import EarlyWarn from "./Pages/EarlyWarn/EarlyWarn";
import Heatmap from "./Pages/Heatmap/Heatmap";
import WindRoseChart from "./Components/Windrose/Windrose";
import DashboardHome from "./Pages/Dashboard/DashboardHome";
import Topbar from "./Pages/Dashboard/Topbar";
import Sidebar from "./Pages/Dashboard/Sidebar";
import LogMaintenance from "./Pages/Dashboard/LogMaintenance";
import SOP from "./Pages/Dashboard/SOP";
import DH from "./Pages/Dashboard/DataHistory";
import Addlog from "./Pages/Dashboard/AddLog2";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./Components/Dashboard/ThemeDashboard";
import { StationList } from "../src/data/StationList";

const station = StationList();
const stationRoutes = station.map((item, i) => (
  <Route key={i} path={item.url} element={item.element} />
));

function isDashboardRoute(pathname) {
  const dashboardPaths = [
    "/dashboard",
    "/logmaintenance",
    "/SOP",
    "/datahistorical",
    "/addlog",
  ];
  return dashboardPaths.includes(pathname);
}

function ProtectedRoute({ children, isLoggedIn }) {
  return isLoggedIn ? children : <Navigate to="/login" />;
}

export default function App() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [isSidebar, setIsSidebar] = useState(true);
  const [theme, colorMode] = useMode();
  const [logs, setLogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsLoggedIn(true);
      setAccessToken(token);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
    setAccessToken("");
    navigate("/login");
  };

  const handleAddLog = (newLog) => {
    if (newLog.id) {
      setLogs(logs.map((log) => (log.id === newLog.id ? newLog : log)));
    } else {
      newLog.id = Date.now();
      setLogs([...logs, newLog]);
    }
  };

  const isDashboard = isDashboardRoute(location.pathname);

  return (
    <>
      {isDashboard ? (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
              <Sidebar
                isCollapsed={isSidebar}
                setIsCollapsed={setIsSidebar}
                selected={location.pathname}
                setSelected={() => {}}
              />
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} handleLogout={handleLogout} />
                <Routes>
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <DashboardHome />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/logmaintenance"
                    element={
                      <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <LogMaintenance logs={logs} setLogs={setLogs} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/SOP"
                    element={
                      <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <SOP />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/datahistorical"
                    element={
                      <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <DH />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/addlog"
                    element={
                      <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <Addlog logs={logs} onAddLog={handleAddLog} />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </main>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/publication" element={<Publication />} />
            <Route path="/about-air-and-radon" element={<AboutAnR />} />
            <Route path="/info-kebencanaan" element={<InfoKebencanaan />} />
            <Route path="/our-station" element={<OurStation />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setAccessToken={setAccessToken} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/get-early-warning" element={<EarlyWarn />} />
            <Route path="/heatmap" element={<Heatmap />} />
            <Route path="/windrose" element={<WindRoseChart />} />
            {stationRoutes}
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}